import { defineStore } from 'pinia'
import useNotifications from '@/composables/useNotifications'

export const useErrorsStore = defineStore('errorsStore', () => {
  const { toastify } = useNotifications()

  const errorCodes = {
    'auth/user-not-found': 'Пользователь с таким email не зарегистрирован',
    'auth/wrong-password': 'Неверный пароль',
    'auth/email-already-in-use': 'Такой email уже зарегистрирован',
    'auth/invalid-email': 'Проверьте привальность написания email',
    'auth/argument-error': 'Проверьте правильность заполнения полей',
    'auth/too-many-requests': 'Слишком много запросов. Попробуйте позже',
    'permission-denied': 'Нет доступа',
    unavailable: 'Нет доступа к интернету. Обновите страницу после восстановления соединения'
  }

  function setError(err) {
    console.log(err)

    if (err.code && errorCodes[err.code]) {
      toastify.error(errorCodes[err.code])
    } else {
      toastify.error('[Ошибка]: ' + err)
    }
  }

  return { setError }
})
