import icons from '@/utils/html.icons'
import { getApp } from 'firebase/app'
import { getFirestore, updateDoc, doc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

export default {
  actions: {
    async createWordpressHtmlLanding({ dispatch, commit, getters }, courseInfo) {
      if (!courseInfo) {
        commit('setError', 'Ошибка')
        return false
      }

      let imgBanner
      if (courseInfo.bannerWhite) {
        imgBanner = `<!-- wp:image {"className":"size-large"} -->
         <figure class="wp-block-image size-large"><img src="${courseInfo.bannerWhite}"`
        if (courseInfo.publicName) {
          imgBanner = imgBanner + ` alt="Курс ${courseInfo.publicName}"`
        }
        imgBanner = imgBanner + `/></figure>
          <!-- /wp:image -->`
      }

      let h2
      if (courseInfo.publicName) {
        h2 = `<!-- wp:heading -->
          <h2>Курс "${courseInfo.publicName}"</h2>
          <!-- /wp:heading -->`
      }

      let description
      if (courseInfo.description) {
        description = `<!-- wp:paragraph -->
          <p>${courseInfo.description}</p>
          <!-- /wp:paragraph -->`
      }

      let otherLevels
      if (courseInfo.categories && courseInfo.categories.length) {
        const mainCategoryId = courseInfo.categories[0]

        if (!getters.categories[mainCategoryId]) {
          await dispatch('fetchCategory', mainCategoryId)
        }

        if (getters.categories[mainCategoryId]) {
          const category = getters.categories[mainCategoryId]
          if (category.name) {
            otherLevels = `<!-- wp:paragraph -->
              <p><a href="https://sreda31.com/${category.name}/`
          }
        }
      }

      if (otherLevels) {
        if (courseInfo.headers && courseInfo.headers.length) {
          const mainHeaderId = courseInfo.headers[0]

          if (!getters.headers[mainHeaderId]) {
            await dispatch('fetchHeader', mainHeaderId)
          }

          if (getters.headers[mainHeaderId]) {
            const header = getters.headers[mainHeaderId]

            if (header.name) {
              otherLevels = otherLevels + `${header.name}/">Посмотреть другие уровни курса</a></p>
                <!-- /wp:paragraph -->`
            } else {
              otherLevels = null
            }
          }
        } else {
          otherLevels = null
        }
      }

      let softRestrictions
      if (courseInfo.softRestrictions) {
        softRestrictions = `<!-- wp:paragraph -->
          <p>${courseInfo.softRestrictions}</p>
          <!-- /wp:paragraph -->`
      }

      let level
      if (courseInfo.level) {
        if (!getters.levels[courseInfo.level]) {
          await dispatch('fetchLevel', courseInfo.level)
        }

        if (getters.levels[courseInfo.level] && getters.levels[courseInfo.level].name) {
          level = `<!-- wp:paragraph -->
            <p><img src="${icons.level.url}" alt="${icons.level.text}" width="30" height="30"><strong> ${icons.level.text}: </strong><a href="https://sreda31.com/help/#course-level" target="_blank">${getters.levels[courseInfo.level].name}</a>.</p>
            <!-- /wp:paragraph -->`
        }
      }

      let requiredSkills
      if (courseInfo.requiredSkills && courseInfo.requiredSkills.length) {
        requiredSkills = `<!-- wp:paragraph -->
          <p><img src="${icons.requiredSkills.url}" alt="${icons.requiredSkills.text}" width="30" height="30"><strong> ${icons.requiredSkills.text}: </strong></p>
          <!-- /wp:paragraph -->

          <!-- wp:list -->
          <ul>`

        for (const skillIdx in courseInfo.requiredSkills) {
          requiredSkills = requiredSkills + '<li>'
          if (courseInfo.requiredSkills[skillIdx].link) {
            requiredSkills = requiredSkills + '<a href="' + courseInfo.requiredSkills[skillIdx].link + '">' + courseInfo.requiredSkills[skillIdx].text + '</a>'
          } else {
            requiredSkills = requiredSkills + courseInfo.requiredSkills[skillIdx].text
          }

          if (+skillIdx + 1 === courseInfo.requiredSkills.length) {
            requiredSkills = requiredSkills + '.</li>'
          } else {
            requiredSkills = requiredSkills + ';</li>'
          }
        }

        requiredSkills = requiredSkills + '</ul><!-- /wp:list -->'
      }

      let blocks
      if (courseInfo.blocks && courseInfo.blocks.length) {
        blocks = `<!-- wp:paragraph -->
          <p><img src="${icons.blocks.url}" alt="${icons.blocks.text}" width="30" height="30"><strong> ${icons.blocks.text}: </strong></p>
          <!-- /wp:paragraph -->

          <!-- wp:list {"ordered":true} -->
          <ol>`

        for (const blockIdx in courseInfo.blocks) {
          blocks = blocks + '<li>' + courseInfo.blocks[blockIdx].name

          if (courseInfo.blocks[blockIdx].content && courseInfo.blocks[blockIdx].content.length) {
            blocks = blocks + ':'
            blocks = blocks + '<ul>'
            for (const contentIdx in courseInfo.blocks[blockIdx].content) {
              blocks = blocks + '<li>' + courseInfo.blocks[blockIdx].content[contentIdx]

              if (+blockIdx + 1 === courseInfo.blocks.length && +contentIdx + 1 === courseInfo.blocks[blockIdx].content.length) {
                blocks = blocks + '.</li>'
              } else {
                blocks = blocks + ';</li>'
              }
            }
            blocks = blocks + '</ul></li>'
          } else {
            if (+blockIdx + 1 === courseInfo.blocks.length) {
              blocks = blocks + '.</li>'
            } else {
              blocks = blocks + ';</li>'
            }
          }
        }
        blocks = blocks + '</ol><!-- /wp:list -->'
      }

      let duration
      if (courseInfo.duration) {
        duration = `<!-- wp:paragraph -->
          <p><img src="${icons.duration.url}" alt="${icons.duration.text}" width="30" height="30"><strong> ${icons.duration.text}: </strong>`

        const hours = Math.floor(+courseInfo.duration / 60)

        let mins
        if (hours >= 1) {
          if ((+courseInfo.duration - Math.floor(+hours * 60)) > 0) {
            mins = Math.floor(+courseInfo.duration - Math.floor(+hours * 60))
          }
        } else {
          mins = +courseInfo.duration
        }

        if (hours >= 1) {
          duration = duration + hours + ' ч.'
        }
        if (mins) {
          duration = duration + ' ' + mins + ' мин.'
        }

        duration = duration + '</p><!-- /wp:paragraph -->'
      }

      let price
      if (courseInfo.priceBase !== undefined && courseInfo.active !== false) {
        price = `<!-- wp:paragraph -->
          <p><img src="${icons.price.url}" alt="${icons.price.text}" width="30" height="30"><strong> ${icons.price.text}: </strong>`

        if (+courseInfo.priceBase === 0) {
          price = price + 'бесплатно.</p><!-- /wp:paragraph -->'
        } else {
          price = price + `</p><!-- /wp:paragraph -->

              <!-- wp:html -->
              <table>
              <tbody>
              <tr>
              <td style="text-align: center;">Тариф Стандарт</td>
              <td style="text-align: center;">Тариф Gold</td>
              <td style="text-align: center;">Тариф Platinum</td>
              </tr>
              <tr>
              <td style="text-align: left;"><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Участие в вебинаре<br /><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Учебные материалы</td>
              <td style="text-align: left;"><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Участие в вебинаре<br /><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Учебные материалы<br /><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Доступ к записи вебинара<br />сроком на 1 месяц</td>
              <td style="text-align: left;"><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Участие в вебинаре<br /><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Учебные материалы<br /><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Доступ к записи вебинара<br />сроком на 3 месяца<br /><img src="https://sreda31.com/wp-content/uploads/2017/11/icon_tick.svg" alt="V" width="20" height="20" /> Сертификат</td>
              </tr>
              <tr>
              <td style="text-align: center;">${courseInfo.priceBase} рублей</td>
              <td style="text-align: center;">${Math.floor((courseInfo.priceBase * 1.8) / 100) * 100} рублей</td>
              <td style="text-align: center;">${Math.floor((courseInfo.priceBase * 2.226) / 100) * 100} рублей</td>
              </tr>
              <tr>
              <td style="text-align: right;"><img src="https://sreda31.com/wp-content/uploads/2017/10/icon_discount.svg" alt="Скидка" width="30" height="30" /></td>
              <td style="text-align: left;" colspan="2">Стоимость участия при <a href="https://sreda31.com/discount/" target="_blank" rel="noopener noreferrer">ранней регистрации</a>:</td>
              </tr>
              <tr>
              <td style="text-align: center;"><strong>${Math.floor((courseInfo.priceBase * 0.9) / 10) * 10}</strong> рублей</td>
              <td style="text-align: center;"><strong>${Math.floor(((Math.floor((courseInfo.priceBase * 1.8) / 100) * 100) * 0.9) / 10) * 10}</strong> рублей</td>
              <td style="text-align: center;"><strong>${Math.floor(((Math.floor((courseInfo.priceBase * 2.226) / 100) * 100) * 0.9) / 10) * 10}</strong> рублей</td>
              </tr>
              </tbody>
              </table>
              <!-- /wp:html -->`
        }
      }

      let schedule = `<!-- wp:paragraph -->
        <p><img src="${icons.schedule.url}" alt="${icons.schedule.text}" width="30" height="30"><strong><a href="https://sreda31.com/timetable/" target="_blank" rel="noopener noreferrer"> ${icons.schedule.text}:</a></strong></p>
        <!-- /wp:paragraph -->`
      if (courseInfo.active !== false) {
        schedule = schedule + `<!-- wp:html -->
            <widget-schedule id="sreda31-widget-schedule" courseidsdata="${courseInfo.landingPage}">Загружаю...</widget-schedule>
          <!-- /wp:html -->
          `
      } else {
        schedule = schedule + `<!-- wp:paragraph -->
        <widget-email widgetid="closedcourse" landingpage="${courseInfo.landingPage}">
          Загружаю...
        </widget-email>
        <!-- /wp:paragraph -->`
      }

      const allCoursesList = `<!-- wp:shortcode -->
        [adinserter block="1"]
        <!-- /wp:shortcode -->`

      const ads = `<!-- wp:shortcode -->
        [adinserter block="4"]
        <!-- /wp:shortcode -->`

      let html = ''
      if (imgBanner) { html = html + imgBanner }
      if (h2) { html = html + h2 }
      if (description) { html = html + description }
      if (otherLevels) { html = html + otherLevels }
      if (softRestrictions) { html = html + softRestrictions }
      if (level) { html = html + level }
      if (requiredSkills) { html = html + requiredSkills }
      if (blocks) { html = html + blocks }
      if (duration) { html = html + duration }
      if (price) { html = html + price }
      if (schedule) { html = html + schedule }
      if (allCoursesList) { html = html + allCoursesList }
      if (ads) { html = html + ads }

      return html
    },
    async createWordpressPage({ dispatch, commit, getters }, { pageInfo, type }) {
      if (!pageInfo) {
        commit('setError', 'Ошибка')
        return false
      }

      if (!pageInfo.landingPage) {
        commit('setError', 'Не задана страница')
        return false
      }

      if (!type) {
        commit('setError', 'Не задан тип действия')
        return false
      }

      const data = {
        type: type,
        status: 'publish' // publish, future, draft, pending, private
      }

      if (type === 'create') {
        if (pageInfo.categories && pageInfo.categories.length) {
          const mainCategoryId = pageInfo.categories[0]

          if (!getters.categories[mainCategoryId]) {
            await dispatch('fetchCategory', mainCategoryId)
          }

          if (getters.categories[mainCategoryId]) {
            const category = getters.categories[mainCategoryId]
            data.parentSlug = category.name
          }
        }
      }

      try {
        const html = await dispatch('createWordpressHtmlLanding', pageInfo)
        if (html && html.length) {
          data.content = html

          if (type === 'edit' && pageInfo.landingPage && pageInfo.landingPageCreated && pageInfo.landingPage === pageInfo.landingPageCreated) {
            data.slug = pageInfo.landingPage
          }

          if (type === 'create' && pageInfo.landingPage && (!pageInfo.landingPageCreated || pageInfo.landingPageCreated !== pageInfo.landingPage)) {
            data.slug = pageInfo.landingPage
          }

          if (pageInfo.publicName) {
            data.title = 'Курс ' + pageInfo.publicName
          }

          const functions = getFunctions(getApp())
          const createWordpressPageFunction = httpsCallable(functions, 'createWordpressPage')
          let answer = await createWordpressPageFunction(data)

          if (answer && answer.data) {
            answer = JSON.parse(answer.data)

            if (!pageInfo.landingPageCreated || pageInfo.landingPageCreated !== pageInfo.landingPage) {
              await commit('setCourse', {
                courseId: answer.id,
                landingPageCreated: pageInfo.landingPage
              })

              const courseRef = doc(getFirestore(), `courses/${answer.id}`)
              await updateDoc(courseRef, {
                landingPageCreated: pageInfo.landingPage
              })
            }
          }

          return answer
        }
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async makeWordpressPageDraft({ commit }, { slug, docum }) {
      if (!slug || !docum) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const data = {
          type: 'edit',
          slug: slug,
          status: 'draft' // publish, future, draft, pending, private
        }

        const functions = getFunctions(getApp())
        const editWordpressPageFunction = httpsCallable(functions, 'createWordpressPage')
        let answer = await editWordpressPageFunction(data)

        if (answer && answer.data) {
          answer = JSON.parse(answer.data)

          const documRef = doc(getFirestore(), docum)
          await updateDoc(documRef, {
            landingPageCreated: null
          })
            .then(async () => {
              await commit('setCourse', {
                courseId: answer.id,
                landingPageCreated: null
              })
            })
        }

        return answer
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async createHeaderPage({ dispatch, commit, getters }, { pageInfo, type, coursesForHeader }) {
      if (!pageInfo || !coursesForHeader || !coursesForHeader.length) {
        commit('setError', 'Ошибка')
        return false
      }

      if (!pageInfo.name) {
        commit('setError', 'Не задана страница')
        return false
      }

      if (!type) {
        commit('setError', 'Не задан тип действия')
        return false
      }

      const data = {
        type: type,
        status: 'publish' // publish, future, draft, pending, private
      }

      if (type === 'create') {
        if (pageInfo.category) {
          if (!getters.categories[pageInfo.category]) {
            await dispatch('fetchCategory', pageInfo.category)
          }

          if (getters.categories[pageInfo.category]) {
            const category = getters.categories[pageInfo.category]
            data.parentSlug = category.name
          }
        }
      }

      try {
        const html = await dispatch('createHeaderHtmlLanding', { pageInfo, coursesForHeader })
        if (html && html.length) {
          data.content = html

          if (type === 'edit' && pageInfo.name && pageInfo.headerPageCreated && pageInfo.name === pageInfo.headerPageCreated) {
            data.slug = pageInfo.name
          }

          if (type === 'create' && pageInfo.name && (!pageInfo.headerPageCreated || pageInfo.headerPageCreated !== pageInfo.name)) {
            data.slug = pageInfo.name
          }

          if (pageInfo.fullName) {
            data.title = pageInfo.fullName
          }

          const functions = getFunctions(getApp())
          const editWordpressPageFunction = httpsCallable(functions, 'createWordpressPage')
          let answer = await editWordpressPageFunction(data)

          if (answer && answer.data) {
            answer = JSON.parse(answer.data)

            if (!pageInfo.headerPageCreated || pageInfo.headerPageCreated !== pageInfo.name) {
              // pageInfo.headerId = answer.id
              await commit('setHeader', pageInfo)

              const headerRef = doc(getFirestore(), `settings/courses/headers/${pageInfo.headerId}`)
              await updateDoc(headerRef, {
                headerPageCreated: pageInfo.name
              })
            }
          }

          return answer
        }
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async createHeaderHtmlLanding({ dispatch, commit, getters }, { pageInfo, coursesForHeader }) {
      if (!pageInfo || !coursesForHeader || !coursesForHeader.length) {
        commit('setError', 'Ошибка')
        return false
      }

      let imgBanner
      if (pageInfo.banner) {
        imgBanner = `<!-- wp:image {"className":"size-large"} -->
         <figure class="wp-block-image size-large"><img src="${pageInfo.banner}"`
        if (pageInfo.fullName) {
          imgBanner = imgBanner + ` alt="Курс ${pageInfo.fullName}"`
        }
        imgBanner = imgBanner + `/></figure>
          <!-- /wp:image -->`
      }

      let h2
      if (pageInfo.fullName) {
        h2 = `<!-- wp:heading -->
          <h2>${pageInfo.fullName}</h2>
          <!-- /wp:heading -->`
      }

      const description = `<!-- wp:paragraph -->
        <p><img src="${icons.blocks.url}" alt="${icons.blocks.text}" width="30" height="30"><strong> Для продолжения выберите один из курсов:</strong></p>
        <!-- /wp:paragraph -->`

      let lines = ''
      for (const courseId of coursesForHeader) {
        if (!getters.courses[courseId]) {
          await dispatch('fetchCourse', courseId)
        }

        const course = getters.courses[courseId]

        if (course.landingPageCreated && course.publicName) {
          let courseUrl = 'https://sreda31.com/'
          if (course.categories) {
            const mainCategory = course.categories[0]

            if (!getters.categories[mainCategory]) {
              await dispatch('fetchCategory', mainCategory)
            }

            courseUrl = courseUrl + getters.categories[mainCategory].name + '/'
          }
          courseUrl = courseUrl + course.landingPageCreated + '/'

          const line = '<li><a href="' + courseUrl + '">Курс "' + course.publicName + '"</a></li>'

          if (lines.indexOf(line) < 0) {
            lines = lines + line
          }
        }
      }

      const coursesList = `<!-- wp:list -->
        <ul>${lines}</ul>
        <!-- /wp:list -->`

      const schedule = `<!-- wp:paragraph -->
        <p><img src="${icons.schedule.url}" alt="${icons.schedule.text}" width="30" height="30"><strong><a href="https://sreda31.com/timetable/" target="_blank" rel="noopener noreferrer"> ${icons.schedule.text}:</a></strong></p>
        <!-- /wp:paragraph -->

        <!-- wp:html -->
          <widget-schedule id="sreda31-widget-schedule" headeridsdata="${pageInfo.name}">Загружаю...</widget-schedule>
        <!-- /wp:html -->`

      const allCoursesList = `<!-- wp:shortcode -->
        [adinserter block="1"]
        <!-- /wp:shortcode -->`

      const ads = `<!-- wp:shortcode -->
        [adinserter block="4"]
        <!-- /wp:shortcode -->`

      let html = ''
      if (imgBanner) { html = html + imgBanner }
      if (h2) { html = html + h2 }
      if (description) { html = html + description }
      if (lines.length) { html = html + coursesList }
      if (schedule) { html = html + schedule }
      if (allCoursesList) { html = html + allCoursesList }
      if (ads) { html = html + ads }

      return html
    },
    async createCategoryPage({ dispatch, commit, getters }, { pageInfo, type, coursesForCategory }) {
      if (!pageInfo || !coursesForCategory || !coursesForCategory.length) {
        commit('setError', 'Ошибка')
        return false
      }

      if (!pageInfo.name) {
        commit('setError', 'Не задана страница')
        return false
      }

      if (!type) {
        commit('setError', 'Не задан тип действия')
        return false
      }

      const useHeaders = []
      const coursesWithoutHeaders = []

      for (const courseId of coursesForCategory) {
        const course = getters.courses[courseId]

        if (!course.categories || !course.categories[0] || !course.landingPageCreated) {
          commit('setError', 'Не хватает данных по курсу: ' + courseId)
          return false
        }

        const mainCategoryId = course.categories[0]

        if (!getters.categories[mainCategoryId]) {
          await dispatch('fetchCategory', mainCategoryId)
        }

        if (course.headers && course.headers[0]) {
          const mainHeaderId = course.headers[0]

          if (!getters.headers[mainHeaderId]) {
            await dispatch('fetchHeader', mainHeaderId)
          }

          if (!useHeaders.includes(mainHeaderId)) {
            useHeaders.push(mainHeaderId)
          }
        } else {
          if (!coursesWithoutHeaders.includes(courseId)) {
            coursesWithoutHeaders.push(courseId)
          }
        }
      }

      if (!useHeaders.length) {
        commit('setError', 'Ошибка')
        return false
      }

      const data = {
        type: type,
        status: 'publish' // publish, future, draft, pending, private
      }

      try {
        const html = await dispatch('createCategoryHtmlLanding', { pageInfo, useHeaders, coursesWithoutHeaders })

        if (html && html.length) {
          data.content = html

          if (type === 'edit' && pageInfo.name && pageInfo.categoryPageCreated && pageInfo.name === pageInfo.categoryPageCreated) {
            data.slug = pageInfo.name
          }

          if (type === 'create' && pageInfo.name && (!pageInfo.categoryPageCreated || pageInfo.categoryPageCreated !== pageInfo.name)) {
            data.slug = pageInfo.name
          }

          if (pageInfo.fullName) {
            data.title = pageInfo.fullName
          }

          const functions = getFunctions(getApp())
          const editWordpressPageFunction = httpsCallable(functions, 'createWordpressPage')
          let answer = await editWordpressPageFunction(data)

          if (answer && answer.data) {
            answer = JSON.parse(answer.data)

            if (!pageInfo.categoryPageCreated || pageInfo.categoryPageCreated !== pageInfo.name) {
              pageInfo.categoryId = answer.id
              await commit('setCategory', pageInfo)

              const categoryRef = doc(getFirestore(), `settings/courses/categories/${answer.id}`)
              await updateDoc(categoryRef, {
                categoryPageCreated: pageInfo.name
              })
            }
          }

          return answer
        }
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async createCategoryHtmlLanding({ dispatch, commit, getters }, { pageInfo, useHeaders, coursesWithoutHeaders }) {
      if (!pageInfo || !useHeaders || !useHeaders.length) {
        commit('setError', 'Ошибка')
        return false
      }

      const description = `<!-- wp:paragraph -->
        <p><strong> Для продолжения выберите направление:</strong></p>
        <!-- /wp:paragraph -->`

      const headerRow = `<!-- wp:paragraph -->
        <p>
          <a href="https://sreda31.com/${pageInfo.name}/"><img src="${pageInfo.logoSmallUrl}" width="30" alt="${pageInfo.fullName}"></a> <a href="https://sreda31.com/${pageInfo.name}/"><strong>&nbsp;${pageInfo.fullName}</strong></a>
        </p>
      <!-- /wp:paragraph -->`

      let lines = ''

      for (const headerId of useHeaders) {
        if (!getters.headers[headerId]) {
          await dispatch('fetchHeader', headerId)
        }

        const header = getters.headers[headerId]

        if (!header || !header.headerPageCreated || !header.fullName || !header.category || !header.logoSmallUrl) {
          commit('setError', 'Не хватает данных по хедеру: ' + headerId)
          return
        }

        if (!getters.categories[header.category]) {
          await this.$store.dispatch('fetchCategory', header.category)
        }

        const category = getters.categories[header.category]

        if (!category.name) {
          commit('setError', 'Не хватает данных по категории: ' + header.category)
          return
        }

        const headerUrl = 'https://sreda31.com/' + category.name + '/' + header.headerPageCreated + '/'

        const line = '<tr><td style="text-align: center;"><a href="' + headerUrl + '"><img src="' + header.logoSmallUrl + '" width="30" alt="' + header.fullName + '"></a></td><td style="text-align: left;"><a href="' + headerUrl + '">&nbsp;"' + header.fullName + '"</a></td></tr>'

        if (lines.indexOf(line) < 0) {
          lines = lines + line
        }
      }

      if (coursesWithoutHeaders && coursesWithoutHeaders.length) {
        for (const i in coursesWithoutHeaders) {
          const courseId = coursesWithoutHeaders[i]

          if (!getters.courses[courseId]) {
            await dispatch('fetchCourse', courseId)
          }

          const course = getters.courses[courseId]

          if (course.landingPageCreated && course.publicName) {
            let courseUrl = 'https://sreda31.com/'
            if (course.categories) {
              const mainCategory = course.categories[0]

              if (!getters.categories[mainCategory]) {
                await dispatch('fetchCategory', mainCategory)
              }

              courseUrl = courseUrl + getters.categories[mainCategory].name + '/'
            }
            courseUrl = courseUrl + course.landingPageCreated + '/'

            const line = '<tr><td style="text-align: center;"><a href="' + courseUrl + '"><img src="' + course.logoSmall + '" width="30" alt="' + course.publicName + '"></a></td><td style="text-align: left;"><a href="' + courseUrl + '">&nbsp;Курс "' + course.publicName + '"</a></td></tr>'

            if (lines.indexOf(line) < 0) {
              lines = lines + line
            }
          }
        }
      }

      const coursesList = '<!-- wp:html --><table><tbody>' + lines + '</tbody></table><!-- /wp:html -->'

      const schedule = `<!-- wp:paragraph -->
        <p><img src="${icons.schedule.url}" alt="${icons.schedule.text}" width="30" height="30"><strong><a href="https://sreda31.com/timetable/" target="_blank" rel="noopener noreferrer"> ${icons.schedule.text}:</a></strong></p>
        <!-- /wp:paragraph -->

        <!-- wp:html -->
            <widget-schedule id="sreda31-widget-schedule" categoryidsdata="${pageInfo.name}">Загружаю...</widget-schedule>
        <!-- /wp:html -->`

      const allCoursesList = `<!-- wp:shortcode -->
        [adinserter block="1"]
        <!-- /wp:shortcode -->`

      const ads = `<!-- wp:shortcode -->
        [adinserter block="4"]
        <!-- /wp:shortcode -->`

      let html = ''
      if (headerRow) { html = html + headerRow }
      if (description) { html = html + description }
      if (lines.length) { html = html + coursesList }
      if (schedule) { html = html + schedule }
      if (allCoursesList) { html = html + allCoursesList }
      if (ads) { html = html + ads }

      return html
    },
    async createTimetablePage({ dispatch, commit, getters }, { categories, courses }) {
      if (!categories || !categories.length || !courses || !Object.keys(courses).length) {
        commit('setError', 'Ошибка')
        return false
      }

      const data = {
        type: 'edit',
        status: 'publish', // publish, future, draft, pending, private
        slug: 'timetable',
        title: 'Расписание курсов',
        pageId: 41
      }

      for (const categoryIdx in categories) {
        const categoryId = categories[categoryIdx]
        if (!getters.categories[categoryId]) {
          await dispatch('fetchCategory', categoryId)
        }

        for (const courseIdx in courses[categoryId]) {
          const courseId = courses[categoryId][courseIdx]
          if (!getters.courses[courseId]) {
            await dispatch('fetchCourse', courseId)
          }
        }
      }

      try {
        const html = await dispatch('createTimetableHtmlLanding', { categories, courses })

        if (html && html.length) {
          data.content = html

          const functions = await getFunctions(getApp())
          const editWordpressPageFunction = httpsCallable(functions, 'createWordpressPage')
          const answer = await editWordpressPageFunction(data)
          if (answer && answer.data) { return true }
        }
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async createTimetableHtmlLanding({ dispatch, commit, getters }, { categories, courses }) {
      if (!categories || !categories.length || !courses || !Object.keys(courses).length) {
        commit('setError', 'Ошибка')
        return false
      }

      const headerRow = `<!-- wp:heading -->
        <h2>Расписание курсов</h2>
        <!-- /wp:heading -->`

      const description = `<!-- wp:paragraph -->
        <p><strong>Ближайшие группы:</strong><p>
        <!-- /wp:paragraph -->`

      const schedule = `<!-- wp:html -->
            <widget-schedule id="sreda31-widget-schedule" timetabledata="true">Загружаю...</widget-schedule>
          <!-- /wp:html -->
          `

      let lines = `<!-- wp:paragraph -->
        <p>&nbsp;</p>
        <!-- /wp:paragraph -->

        <!-- wp:paragraph -->
        <p><strong>Все наши курсы:</strong></p>
        <!-- /wp:paragraph -->`

      for (const i in categories) {
        const categoryId = categories[i]
        const category = getters.categories[categoryId]

        const headerRowData = '<!-- wp:paragraph --><p><a href="https://sreda31.com/' + category.name + '/"><img src="' + category.logoSmallUrl + '" width ="30" alt="' + category.fullName + '"></a> <a href="https://sreda31.com/' + category.name + '/">&nbsp;' + category.fullName + '</a></p><!-- /wp:paragraph -->'
        if (lines.indexOf(headerRowData) < 0) {
          lines = lines + headerRowData
        }

        if (courses[categoryId].length) {
          lines = lines + '<!-- wp:html --><table><tbody>'
        }

        for (const courseIdx in courses[categoryId]) {
          const courseId = courses[categoryId][courseIdx]
          const course = getters.courses[courseId]

          const mainCategoryId = course.categories[0]
          if (!getters.categories[mainCategoryId]) {
            await dispatch('fetchCategory', mainCategoryId)
          }
          const mainCategory = getters.categories[mainCategoryId]

          if (mainCategory && mainCategory.name && course && course.landingPageCreated && course.logoSmall && course.publicName) {
            const newLine = '<tr><td style="text-align: center;"><a href="https://sreda31.com/' + mainCategory.name + '/' + course.landingPageCreated + '/"><img src="' + course.logoSmall + '" width="30" alt="' + course.publicName + '"></a></td><td style="text-align: left;"><a href="https://sreda31.com/' + mainCategory.name + '/' + course.landingPageCreated + '/">&nbsp;' + course.publicName + '</a></td></tr>'

            lines = lines + newLine
          }
        }

        if (courses[categoryId].length) {
          lines = lines + '</tbody></table><!-- /wp:html -->'
        }
      }

      const footnotes = '<!-- wp:paragraph --><p>Любой наш курс можно пройти индивидуально. Для этого&nbsp;<a href="https://sreda31.com/feedback/">свяжитесь с нашим менеджером</a></p><!-- /wp:paragraph -->'

      let html = ''
      if (headerRow) { html = html + headerRow }
      if (description) { html = html + description }
      if (schedule) { html = html + schedule }
      if (lines.length) { html = html + lines }
      if (footnotes) { html = html + footnotes }

      return html
    }
  }
}
