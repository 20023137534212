import { createStore } from 'vuex'

import auth from './auth'
import groups from './groups'
import categories from './categories'
import courses from './courses'
import headers from './headers'
import orders from './orders'
import placesToShare from './places-to-share'
import settings from './settings'
import stats from './stats'
import students from './students'
import tasks from './tasks'
import timetable from './timetable'
import functionsWordpress from './wordpress'

export default createStore({
  state: {
    error: null,
    openedLink: null
  },
  mutations: {
    setError(state, error) {
      state.error = error
    },
    setOpenedLink(state, value) {
      state.openedLink = value
    },
    clearInfo(state) {
      state.error = null
    }
  },
  getters: {
    error: s => s.error,
    openedLink: s => s.openedLink
  },
  modules: {
    auth, groups, categories, courses, headers, orders, placesToShare, settings, stats, students, tasks, timetable, functionsWordpress
  }
})
