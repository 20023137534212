import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { collection, doc, getDocs, getFirestore, orderBy, query, where, onSnapshot, updateDoc } from 'firebase/firestore'
import useNotifications from '../composables/useNotifications'
import { useErrorsStore } from './errors'
import { getAuth } from 'firebase/auth'

export const useFeedbackStore = defineStore('feedbackStore', () => {
  const { toastify } = useNotifications()
  const { setError } = useErrorsStore()

  const feedback = ref({})
  const feedbackUnsubscribe = ref(null)

  const prepareData = (data) => {
    if (!data) { return {} }
    if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }
    return data
  }

  const activeFeedbackIdsArr = computed(() => {
    let ids = Object.keys(feedback.value)

    ids = ids.filter(id => {
      return feedback.value[id].active === true
    })

    if (ids.length && ids.length > 1) {
      ids.sort((a, b) => {
        if (+feedback.value[a].timestamp < +feedback.value[b].timestamp) { return -1 }
        if (+feedback.value[a].timestamp > +feedback.value[b].timestamp) { return 1 }
        return 0
      })
    }

    return ids
  })

  async function subscribeToFeedback() {
    try {
      await unsubscribeFeedback()

      const r = collection(getFirestore(), 'feedback')
      const q = query(r, where('active', '==', true), orderBy('timestamp', 'asc'))

      const unsubscribe = onSnapshot(q, async querySnapshot => {
        let haveFeedback = false

        querySnapshot.docChanges().forEach(async change => {
          if (change.type === 'added') {
            haveFeedback = true
            const feedbackData = prepareData(change.doc.data())
            feedbackData.feedbackId = change.doc.id
            feedback.value[change.doc.id] = feedbackData

            // временно не используется до переноса fetchStudentById на pinia
            // if (feedbackData.studentId && !getters.students[feedbackData.studentId]) {
            //   await dispatch('fetchStudentById', feedbackData.studentId)
            // }
          }
        })

        if (haveFeedback) {
          toastify.warning('Есть новые сообщения обратной связи', { timeout: null })
        }
      }, (error) => {
        setError(error)
      })

      feedbackUnsubscribe.value = unsubscribe
    } catch (e) {
      setError(e)
    }
  }

  async function unsubscribeFeedback() {
    try {
      if (feedbackUnsubscribe.value) {
        await feedbackUnsubscribe.value()
        feedbackUnsubscribe.value = null
      }
    } catch (e) {
      setError(e)
    }
  }

  async function markFeedbackDone(feedbackId) {
    if (!feedbackId) {
      setError('Не указан ID')
      return
    }

    if (!feedback.value[feedbackId]) {
      setError('Сообщение обратной связи не найдено')
      return
    }

    if (!feedback.value[feedbackId].active) { return }

    const uid = getAuth().currentUser?.uid
    if (!uid) {
      setError('Не указан UID')
      return
    }

    const timestamp = new Date()

    const feedbackData = {
      active: false,
      markedDoneUserId: uid,
      markedDoneTimestamp: timestamp
    }

    let toastId

    try {
      toastId = toastify.warning('Сохраняю', { timeout: null })
      const r = doc(getFirestore(), `feedback/${feedbackId}`)
      await updateDoc(r, feedbackData)

      feedback.value[feedbackId] = { ...feedback.value[feedbackId], ...feedbackData }
      toastify.replace(toastId, 'Сохранено', 'success')
    } catch (e) {
      setError(e)
      toastify.remove(toastId)
    }
  }

  async function fetchFeedbackByOrderStudentId(studentId) {
    if (!studentId) {
      setError('Не указан studentId')
      return
    }

    try {
      const r = collection(getFirestore(), 'feedback')
      const queries = [where('studentId', '==', studentId), orderBy('timestamp', 'desc')]
      const q = query(r, ...queries)
      const feedbacks = await getDocs(q)

      if (!feedbacks.empty) {
        feedbacks.forEach(async feedback => {
          if (feedback.exists()) {
            const feedbackData = prepareData(feedback.data())
            feedbackData.feedbackId = feedback.id
            feedback.value[feedback.id] = feedbackData

            // временно не используется до переноса fetchStudentById на pinia
            // if (feedbackData.studentId && !getters.students[feedbackData.studentId]) {
            //   await dispatch('fetchStudentById', feedbackData.studentId)
            // }
          }
        })
      }

      return true
    } catch (e) {
      setError(e)
    }
  }

  return { feedback, feedbackUnsubscribe, activeFeedbackIdsArr, subscribeToFeedback, unsubscribeFeedback, markFeedbackDone, fetchFeedbackByOrderStudentId }
})
