import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { collection, doc, getCountFromServer, getDoc, getDocs, getFirestore, increment, limit, orderBy, query, startAfter, where, writeBatch } from 'firebase/firestore'
import useNotifications from '../composables/useNotifications'
import { useErrorsStore } from './errors'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getApp } from 'firebase/app'
import { customAlphabet } from 'nanoid'

export const useShortLinksStore = defineStore('shortLinksStore', () => {
  const { toastify } = useNotifications()
  const { setError } = useErrorsStore()

  const shortLinks = ref({})
  const allShortLinksFetched = ref(false)
  const lastFetchedShortLink = ref(null)
  const pinnedShortLinksFetched = ref(false)
  const numberOfShortLinks = ref(0)

  const badRequests = ref(null)
  const badIncludes = ref(null)

  const loadingClicks = ref(false)

  const clicks = ref({})
  const clicksTotal = ref(0)
  const allClicksFetched = ref(false)
  const lastFetchedClick = ref(null)
  const fetchedIps = ref([])

  const clicksFilters = ref({
    shortLinkId: null,
    ip: null,
    hideMalicious: false,
    hideSuspicious: false,
    hideClick: false
  })

  function resetClicksFilters() {
    clicksFilters.value.shortLinkId = null
    clicksFilters.value.ip = null
    clicksFilters.value.hideMalicious = false
    clicksFilters.value.hideSuspicious = false
    clicksFilters.value.hideClick = false
  }

  const clicksFiltered = computed(() => {
    if (clicksFilters.value.shortLinkId) { return true }
    if (clicksFilters.value.ip) { return true }
    if (clicksFilters.value.hideClick) { return true }
    if (clicksFilters.value.hideSuspicious) { return true }
    if (clicksFilters.value.hideMalicious) { return true }
    return false
  })

  const allMalitiousClicksFetched = ref(false)
  const allSuspiciousClicksFetched = ref(false)

  const prepareData = (data) => {
    if (!data) { return {} }
    if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }
    return data
  }

  async function fetchShortLinks() {
    if (allShortLinksFetched.value) { return }

    const maxLength = 10

    try {
      const r = collection(getFirestore(), 'shortLinks')
      const queries = [orderBy('timestamp', 'desc'), limit(maxLength)]

      if (lastFetchedShortLink.value) {
        queries.push(startAfter(lastFetchedShortLink.value))
      }

      const q = query(r, ...queries)

      const links = await getDocs(q)
      if (!links.empty) {
        for (const link of links.docs) {
          if (link.exists()) {
            const linkData = prepareData(link.data())
            linkData.id = link.id
            shortLinks.value[link.id] = linkData
          }
        }

        lastFetchedShortLink.value = links.docs[links.docs.length - 1]
      }

      if (links.empty || links.size < maxLength) {
        allShortLinksFetched.value = true
      }
    } catch (e) {
      setError(e)
    }
  }

  async function fetchPinnedShortLinks() {
    if (pinnedShortLinksFetched.value) { return }

    try {
      const r = collection(getFirestore(), 'shortLinks')
      const queries = [orderBy('timestamp', 'desc'), where('pinned', '==', true)]
      const q = query(r, ...queries)

      const links = await getDocs(q)
      if (!links.empty) {
        for (const link of links.docs) {
          if (link.exists()) {
            const linkData = prepareData(link.data())
            linkData.id = link.id
            shortLinks.value[link.id] = linkData
          }
        }
      }

      pinnedShortLinksFetched.value = true
    } catch (e) {
      setError(e)
    }
  }

  async function fetchShotlLinksNumber() {
    if (numberOfShortLinks.value) { return }

    try {
      const clicksRef = collection(getFirestore(), 'shortLinks')
      const snapshot = await getCountFromServer(clicksRef)
      numberOfShortLinks.value = snapshot.data().count
    } catch (e) {
      setError(e)
    }
  }

  async function fetchShortLink(id = '', needToast = false) {
    id = id.trim()
    if (!id) { return }

    if (allShortLinksFetched.value) { return }

    const parts = id.split('sreda31.com/')
    id = parts[parts.length - 1]

    let toastId

    try {
      if (needToast) {
        toastId = toastify.warning('Ищу...', { timeout: null })
      }

      const link = await getDoc(doc(getFirestore(), `shortLinks/${id}`))

      let linkData
      if (link && link.exists()) {
        linkData = prepareData(link.data())
        linkData.id = link.id
        shortLinks.value[link.id] = linkData
      }

      if (needToast) { toastify.remove(toastId) }

      return linkData
    } catch (e) {
      setError(e)
      toastify.remove(toastId)
    }
  }

  async function findShortLinkByFullUrl(url = '', needToast = false) {
    url = url.trim()
    if (!url) { throw new Error('Не указан url') }

    const parts = url.split('?')
    url = parts[0]

    let toastId

    try {
      if (needToast) { toastId = toastify.warning('Ищу...', { timeout: null }) }

      const shortLinksRef = collection(getFirestore(), 'shortLinks')
      const shortLinksQ = query(shortLinksRef, where('url', '==', url), limit(1))
      const answer = await getDocs(shortLinksQ)
      if (answer.empty) { return null }

      for (const link of answer.docs) {
        const linkData = prepareData(link.data())
        linkData.id = link.id
        shortLinks.value[link.id] = linkData
      }

      if (needToast) { toastify.remove(toastId) }

      return answer.docs[0].id
    } catch (e) {
      setError(e)
      toastify.remove(toastId)
    }
  }

  async function pinShortLink(linkId) {
    if (!linkId || !shortLinks.value[linkId]) {
      setError('Нет данных о ссылке')
      return
    }

    let toastId

    try {
      toastId = toastify.warning('Сохраняю', { timeout: null })

      const batch = writeBatch(getFirestore())

      const linkData = {
        pinned: shortLinks.value[linkId].pinned ? false : true
      }

      const linkRef = doc(getFirestore(), `shortLinks/${linkId}`)
      batch.update(linkRef, linkData)

      await batch.commit()

      shortLinks.value[linkId].pinned = linkData.pinned

      toastify.replace(toastId, 'Сохранено', 'success')
    } catch (e) {
      setError(e)
      toastify.remove(toastId)
    }
  }

  async function saveLinkComment({ linkId, comment = '' }) {
    comment = comment.trim()

    if (!linkId || !shortLinks.value[linkId]) {
      setError('Нет данных о ссылке')
      return
    }

    if (shortLinks.value[linkId].comment === comment) { return true }

    let toastId

    try {
      toastId = toastify.warning('Сохраняю', { timeout: null })

      const batch = writeBatch(getFirestore())

      const linkData = {
        comment: comment.trim()
      }

      const linkRef = doc(getFirestore(), `shortLinks/${linkId}`)
      batch.update(linkRef, linkData)

      await batch.commit()

      shortLinks.value[linkId].comment = linkData.comment

      toastify.replace(toastId, 'Сохранено', 'success')
    } catch (e) {
      setError(e)
      toastify.remove(toastId)
    }
  }

  async function deleteShortLink(linkId) {
    if (!linkId) {
      setError('[deleteShortLink]: Нет указан linkId')
      return
    }

    if (!shortLinks.value[linkId]) {
      setError('[deleteShortLink]: Ссылка не загружена')
      return
    }

    let toastId

    try {
      toastId = toastify.warning('Удаляю', { timeout: null })

      const batchArray = []
      batchArray.push(writeBatch(getFirestore()))
      let count = 0
      let batchIndex = 0
      const countLimit = 495

      const clicksIds = []
      const clicksRef = collection(getFirestore(), 'clicks')
      const clicksQ = query(clicksRef, where('linkId', '==', linkId))
      const clicksDocs = await getDocs(clicksQ)
      if (clicksDocs && !clicksDocs.empty) {
        for (const click of clicksDocs.docs) {
          const clickId = click.id
          if (!clicksIds.includes(clickId)) { clicksIds.push(clickId) }
        }
      }

      for (const clickId of clicksIds) {
        const clickRef = doc(getFirestore(), `clicks/${clickId}`)
        batchArray[batchIndex].delete(clickRef)
        count++
        if (count > countLimit) {
          batchArray.push(writeBatch(getFirestore()))
          batchIndex++
          count = 0
        }
      }

      const linkRef = doc(getFirestore(), `shortLinks/${linkId}`)
      batchArray[batchIndex].delete(linkRef)
      count++
      if (count > countLimit) {
        batchArray.push(writeBatch(getFirestore()))
        batchIndex++
        count = 0
      }

      const linkPublicDataRef = doc(getFirestore(), `shortLinks/${linkId}/linkData/public`)
      batchArray[batchIndex].delete(linkPublicDataRef)
      count++
      if (count > countLimit) {
        batchArray.push(writeBatch(getFirestore()))
        batchIndex++
        count = 0
      }

      for (const batch of batchArray) {
        await batch.commit()
      }

      for (const clickId of clicksIds) {
        if (clicks.value[clickId]) {
          delete clicks.value[clickId]
        }
      }

      if (shortLinks.value[linkId]) {
        delete shortLinks.value[linkId]
      }

      toastify.replace(toastId, 'Удалено', 'success')
    } catch (e) {
      toastify.remove(toastId)
      setError(e)
    }
  }

  async function createShortLink(url = '') {
    url = url.trim()
    url = url.toLowerCase()
    if (!url) {
      setError('Не задан исходный url')
      return
    }

    try {
      let id

      const linksInStore = Object.keys(shortLinks.value).filter(key => shortLinks.value[key].url.toLowerCase() === url)
      if (linksInStore.length) {
        id = linksInStore[0]
      } else {
        id = await findShortLinkByFullUrl(url)
      }

      if (!id) {
        const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 7)
        id = nanoid()
        id = id + ''
        id = id.toLowerCase()

        if (!badRequests.value || !badIncludes.value) {
          const functions = getFunctions(getApp())
          const getBadDataForShortLinks = httpsCallable(functions, 'getBadDataForShortLinks')
          const badData = await getBadDataForShortLinks()
          if (!badData || !badData.data) {
            badIncludes.value = []
            badRequests.value = []
          } else {
            if (badData.data.badIncludes) {
              badIncludes.value = badData.data.badIncludes
            } else {
              badIncludes.value = []
            }
            if (badData.data.badRequests) {
              badRequests.value = badData.data.badRequests
            } else {
              badRequests.value = []
            }
          }
        }

        for (const badId of badIncludes.value) {
          if (id.toLowerCase().includes(badId, id.length - badId.length)) {
            return await createShortLink(url)
          }
        }

        if (['-', '_'].includes(id.substring(id.length - 1))) { return await createShortLink(url) }

        for (const badId of badRequests.value) {
          if (badId.toLowerCase().includes(id.toLowerCase())) { return await createShortLink(url) }
        }

        let idExists
        if (shortLinks.value[id]) { idExists = true }
        if (!idExists) { idExists = await fetchShortLink(id, false) }
        if (idExists) { return await createShortLink(url) }

        const batch = writeBatch(getFirestore())

        const linkData = {
          url,
          timestamp: new Date(),
          clicks: 0,
          pinned: false
        }

        const linkRef = doc(getFirestore(), `shortLinks/${id}`)
        batch.set(linkRef, linkData)

        const linkDataRef = doc(getFirestore(), `shortLinks/${id}/linkData/public`)
        batch.set(linkDataRef, { url })

        await batch.commit()

        linkData.id = id
        shortLinks.value[id] = linkData
      }

      return id
    } catch (e) {
      setError(e)
    }
  }

  async function deleteClicks(clicksIds) {
    if (!clicksIds || !clicksIds.length) {
      setError('Нет указан clicksIds')
      return
    }

    let toastId

    try {
      toastId = toastify.warning('Удаляю', { timeout: null })

      const batchArray = []
      batchArray.push(writeBatch(getFirestore()))
      let count = 0
      let batchIndex = 0
      const countLimit = 495

      const linkIds = {}

      for (const clickId of clicksIds) {
        const click = clicks.value[clickId]
        if (!click) {
          setError(`[deleteClicks]: Нет данных о клике ${clickId}`)
          continue
        }

        const clickRef = doc(getFirestore(), `clicks/${clickId}`)
        batchArray[batchIndex].delete(clickRef)
        count++
        if (count > countLimit) {
          batchArray.push(writeBatch(getFirestore()))
          batchIndex++
          count = 0
        }

        if (click.linkId && shortLinks.value[click.linkId]) {
          if (!linkIds[click.linkId]) { linkIds[click.linkId] = 0 }
          linkIds[click.linkId] += 1
        }
      }

      for (const linkId of Object.keys(linkIds)) {
        const linkRef = doc(getFirestore(), `shortLinks/${linkId}`)
        batchArray[batchIndex].update(linkRef, { clicks: increment(-1 * linkIds[linkId]) })
        count++
        if (count > countLimit) {
          batchArray.push(writeBatch(getFirestore()))
          batchIndex++
          count = 0
        }
      }

      for (const batch of batchArray) {
        await batch.commit()
      }

      for (const clickId of clicksIds) {
        delete clicks.value[clickId]
      }

      if (clicksTotal.value) { clicksTotal.value = clicksTotal.value - clicksIds.length }

      for (const linkId of Object.keys(linkIds)) {
        if (shortLinks.value[linkId] && +shortLinks.value[linkId].clicks > 0) {
          shortLinks.value[linkId].clicks = shortLinks.value[linkId].clicks - linkIds[linkId]
        }
      }

      toastify.replace(toastId, 'Удалено', 'success')
    } catch (e) {
      setError(e)
      toastify.remove(toastId)
    }
  }

  async function fetchClicksTotal() {
    if (clicksTotal.value) { return }

    try {
      const clicksRef = collection(getFirestore(), 'clicks')
      const snapshot = await getCountFromServer(clicksRef)
      clicksTotal.value = snapshot.data().count
    } catch (e) {
      setError(e)
    }
  }

  async function fetchClicks(linkId) {
    if (allClicksFetched.value) { return }
    if (linkId && shortLinks.value[linkId] && shortLinks.value[linkId].allClicksFetched) { return }

    const maxLength = 10

    try {
      loadingClicks.value = true

      const clicksRef = collection(getFirestore(), 'clicks')

      const queries = [orderBy('timestamp', 'desc'), limit(maxLength)]

      if (linkId) {
        queries.push(where('linkId', '==', linkId))

        if (shortLinks.value[linkId] && shortLinks.value[linkId].lastFetchedClick) {
          queries.push(startAfter(shortLinks.value[linkId].lastFetchedClick))
        }
      } else {
        if (lastFetchedClick.value) {
          queries.push(startAfter(lastFetchedClick.value))
        }
      }

      const q = query(clicksRef, ...queries)

      const clicksDocs = await getDocs(q)
      if (clicksDocs.empty) {
        if (linkId && shortLinks.value[linkId]) {
          shortLinks.value[linkId].allClicksFetched = true
        } else {
          allClicksFetched.value = true
        }
      } else {
        if (clicksDocs.size < maxLength) {
          if (linkId && shortLinks.value[linkId]) {
            shortLinks.value[linkId].allClicksFetched = true
          } else {
            allClicksFetched.value = true
          }
        }

        for (const click of clicksDocs.docs) {
          const clickData = prepareData(click.data())
          clickData.id = click.id
          clicks.value[click.id] = clickData

          if (clickData.linkId && !shortLinks.value[clickData.linkId]) {
            await fetchShortLink(clickData.linkId)
          }
        }

        if (linkId && shortLinks.value[linkId]) {
          shortLinks.value[linkId].lastFetchedClick = clicksDocs.docs[clicksDocs.docs.length - 1]
        } else {
          lastFetchedClick.value = clicksDocs.docs[clicksDocs.docs.length - 1]
        }
      }
    } catch (e) {
      setError(e)
    } finally {
      loadingClicks.value = false
    }
  }

  async function fetchClicksByIp(ip) {
    if (allClicksFetched.value || !ip || fetchedIps.value.includes(ip)) { return }

    try {
      loadingClicks.value = true

      const clicksRef = collection(getFirestore(), 'clicks')

      const queries = [orderBy('timestamp', 'desc'), where('ip', '==', ip)]
      const q = query(clicksRef, ...queries)

      const clicksDocs = await getDocs(q)
      if (!clicksDocs.empty) {
        for (const click of clicksDocs.docs) {
          const clickData = prepareData(click.data())
          clickData.id = click.id
          clicks.value[click.id] = clickData

          if (clickData.linkId && !shortLinks.value[clickData.linkId]) {
            await fetchShortLink(clickData.linkId)
          }
        }
      }

      if (!fetchedIps.value.includes(ip)) { fetchedIps.value.push(ip) }
    } catch (e) {
      setError(e)
    } finally {
      loadingClicks.value = false
    }
  }

  async function fetchAllClicksByClickType() {
    if (allClicksFetched.value) { return }
    if (!clicksFilters.value.hideClick) { return }
    if (!clicksFilters.value.hideSuspicious && allSuspiciousClicksFetched.value) { return }
    if (!clicksFilters.value.hideMalicious && allMalitiousClicksFetched.value) { return }

    const wheres = []
    if (!clicksFilters.value.hideSuspicious) { wheres.push('suspicious') }
    if (!clicksFilters.value.hideMalicious) { wheres.push('malicious') }
    if (!wheres.length) { return }

    try {
      loadingClicks.value = true

      const clicksRef = collection(getFirestore(), 'clicks')

      const queries = [orderBy('timestamp', 'desc'), where('type', 'in', wheres)]
      const q = query(clicksRef, ...queries)

      const newClicks = {}

      const clicksDocs = await getDocs(q)
      if (!clicksDocs.empty) {
        for (const click of clicksDocs.docs) {
          const clickData = prepareData(click.data())
          clickData.id = click.id

          if (clickData.linkId && !shortLinks.value[clickData.linkId]) {
            await fetchShortLink(clickData.linkId)
          }

          newClicks[click.id] = clickData
        }
      }

      clicks.value = {
        ...clicks.value,
        ...newClicks
      }

      if (!clicksFilters.value.hideSuspicious) { allSuspiciousClicksFetched.value = true }
      if (!clicksFilters.value.hideMalicious) { allMalitiousClicksFetched.value = true }
    } catch (e) {
      setError(e)
    } finally {
      loadingClicks.value = false
    }
  }

  async function setClickType(clickId, type) {
    if (!clickId || !clicks.value[clickId]) {
      setError('Нет данных о клике')
      return
    }

    if (!type || clicks.value[clickId].type === type) { return }

    let toastId

    try {
      toastId = toastify.warning('Сохраняю', { timeout: null })

      const batch = writeBatch(getFirestore())

      const clickRef = doc(getFirestore(), `clicks/${clickId}`)
      batch.update(clickRef, { type })

      await batch.commit()

      clicks.value[clickId].type = type

      toastify.replace(toastId, 'Сохранено', 'success')
    } catch (e) {
      setError(e)
      toastify.remove(toastId)
    }
  }

  return { loadingClicks, allClicksFetched, clicks, fetchedIps, clicksTotal, clicksFilters, clicksFiltered, shortLinks, allShortLinksFetched, numberOfShortLinks, allMalitiousClicksFetched, allSuspiciousClicksFetched, fetchShortLinks, fetchPinnedShortLinks, fetchShotlLinksNumber, fetchShortLink, findShortLinkByFullUrl, pinShortLink, saveLinkComment, deleteShortLink, createShortLink, deleteClicks, fetchClicksTotal, fetchClicks, fetchClicksByIp, fetchAllClicksByClickType, setClickType, resetClicksFilters }
})
