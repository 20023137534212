<template>
  <div class="min-h-screen min-w-min h-auto w-auto inset-0 bg-indigo-200 px-5 pt-12 pb-5">
    <LoaderComponent v-if="loading" />

    <div v-else>
      <Navbar
        @show-sidebar="showSidebar = !showSidebar"
        @show-schedule="showSchedule = $event"
      />

      <SideBar
        :show="showSidebar"
        @show-sidebar="showSidebar = !showSidebar"
      />

      <GroupsSchedule
        v-if="showSchedule"
        @show-schedule="showSchedule = $event"
      />

      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useFeedbackStore } from '../stores/feedback'
import { storeToRefs } from 'pinia'
import store from '../store'

const feedbackStore = useFeedbackStore()
const { subscribeToFeedback, unsubscribeFeedback } = feedbackStore
const { feedbackUnsubscribe } = storeToRefs(feedbackStore)

const Navbar = defineAsyncComponent(() =>
  import('@/components/app/Navbar')
)

const SideBar = defineAsyncComponent(() =>
  import('@/components/app/SideBar')
)

const GroupsSchedule = defineAsyncComponent(() =>
  import('@/components/groups/GroupsSchedule.vue')
)

const loading = ref(true)
const showSidebar = ref(false)
const showSchedule = ref(false)

const route = useRoute()
watch(route, () => {
  showSidebar.value = false
})

onBeforeMount(async () => {
  loading.value = true

  if (!feedbackUnsubscribe.value) { subscribeToFeedback() }

  if (!store.getters.timeZone) {
    const timezoneAnswer = await store.dispatch('fetchTimezone')
    if (!timezoneAnswer) { store.commit('setTimezone', Intl.DateTimeFormat().resolvedOptions().timeZone)}
  }

  if (!store.getters.ordersUnsubscribe) {
    store.dispatch('subscribeToOrders')
  }

  if (!store.getters.studentsUnsubscribe) {
    store.dispatch('subscribeToStudents')
  }

  if (!store.getters.statsUnsubscribe) {
    store.dispatch('subscribeToStats')
  }

  loading.value = false
})

onMounted(() => {
  document.addEventListener('keydown', clickedButton)
})

onBeforeUnmount(async () => {
  document.removeEventListener('keydown', clickedButton)
  await unsubscribeFeedback()
  await store.dispatch('unsubscribeOrders')
  await store.dispatch('unsubscribeStudents')
  await store.dispatch('unsubscribeStats')
})

function clickedButton (event) {
  if (event.code === 'Escape') {
    showSidebar.value = false
  }
}
</script>
