import { getFirestore, getDoc, getDocs, doc, collection, query, writeBatch, where, orderBy, limit, startAfter } from 'firebase/firestore'

export default {
  state: {
    groups: {},
    groupsReset: 0,
    allActiveFutureGroupsFetched: false,
    fetchedAllGroupsForCourses: [],
    lastFetchedGroup: null,
    loadedAllGroups: false,
    showGroupsFilters: false,
    groupsFilters: {
      searchStr: '',
      hasStudents: false,
      showFinishedGroups: false,
      showInActiveGroups: false,
      type: null,
      format: null,
      courseId: null
    },
    groupsScheduleMonth: null,
    groupsScheduleYear: null
  },
  mutations: {
    setShowGroupCourseId(state, value) {
      state.groupsFilters.courseId = value
    },
    setShowGroupFormat(state, value) {
      state.groupsFilters.format = value
    },
    setShowGroupType(state, value) {
      state.groupsFilters.type = value
    },
    setShowInActiveGroups(state, value) {
      state.groupsFilters.showInActiveGroups = value
    },
    setShowFinishedGroups(state, value) {
      state.groupsFilters.showFinishedGroups = value
    },
    setGroupHasStudents(state, value) {
      state.groupsFilters.hasStudents = value
    },
    setShowGroupsFilters(state, value) {
      state.showGroupsFilters = value
    },
    resetGroups(state) {
      state.groupsReset = +new Date()
    },
    setAllActiveFutureGroupsFetched(state) {
      state.allActiveFutureGroupsFetched = true
    },
    setFetchedAllGroupsForCourses(state, courseId) {
      if (courseId && state.fetchedAllGroupsForCourses.indexOf(courseId) < 0) {
        const courses = state.fetchedAllGroupsForCourses.slice(0)
        courses.push(courseId)
        state.fetchedAllGroupsForCourses = courses.slice(0)
      }
    },
    setLoadedAllGroups(state) {
      state.loadedAllGroups = true
    },
    setLastFetchedGroup(state, data) {
      state.lastFetchedGroup = data
    },
    setGroup(state, groupData) {
      if (!groupData || !groupData.groupId) { return }

      if (!state.groups[groupData.groupId]) {
        state.groups[groupData.groupId] = {}
      }

      for (const i in Object.keys(groupData)) {
        const key = Object.keys(groupData)[i]
        if (groupData[key] !== null && groupData[key] !== undefined && typeof Object.keys(groupData[key]) === 'object' && groupData[key].toString() === '[object Object]' && !Array.isArray(groupData[key])) {
          for (const y in Object.keys(groupData[key])) {
            const subKey = Object.keys(groupData[key])[y]

            if (!state.groups[groupData.groupId][key]) {
              state.groups[groupData.groupId][key] = {}
            }

            state.groups[groupData.groupId][key][subKey] = groupData[key][subKey]
          }
        } else {
          state.groups[groupData.groupId][key] = groupData[key]
        }
      }
    },
    setGroupsSearchStr(state, searchStr) {
      state.groupsFilters.searchStr = searchStr
    },
    setGroupsScedulePeriod(state, { month, year }) {
      if (year !== undefined) { state.groupsScheduleYear = year }
      if (month !== undefined) { state.groupsScheduleMonth = month }
    },
    clearGroupsFilters(state) {
      state.groupsFilters = {
        searchStr: '',
        hasStudents: false,
        showFinishedGroups: false,
        showInActiveGroups: false,
        type: null,
        format: null,
        courseId: null
      }
    },
    clearInfo(state) {
      state.groups = {}
      state.allActiveFutureGroupsFetched = false
      state.fetchedAllGroupsForCourses = []
      state.lastFetchedGroup = null
      state.loadedAllGroups = false
      state.showGroupsFilters = false
      state.groupsFilters = {
        searchStr: '',
        hasStudents: false,
        showFinishedGroups: false,
        showInActiveGroups: false,
        type: null,
        format: null,
        courseId: null
      }
      state.groupsScheduleMonth = null,
        state.groupsScheduleYear = null
    }
  },
  actions: {
    async fetchGroup({ dispatch, commit }, groupId) {
      if (!groupId) {
        commit('setError', 'Ошибка')
        return
      }

      try {
        await getDoc(doc(getFirestore(), `groups/${groupId}`))
          .then(async group => {
            if (group.exists()) {
              let groupData = {}
              groupData = group.data()
              groupData.groupId = groupId
              groupData = await dispatch('setDatesToDates', groupData)
              await commit('setGroup', groupData)
              commit('resetGroups')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchLastGroupByNethouseEventId({ dispatch, commit }, courseId) {
      if (!courseId) {
        commit('setError', 'Ошибка')
        return false
      }

      let groupId = null

      try {
        const ref = collection(getFirestore(), 'groups')
        const q = query(ref, where('courseId', '==', courseId), orderBy('nethouseEventId', 'desc'), limit(1))
        const groups = await getDocs(q)
        if (!groups.empty) {
          for (const group of groups.docs) {
            let groupData = group.data()
            groupData.groupId = group.id
            groupId = group.id
            groupData = await dispatch('setDatesToDates', groupData)
            await commit('setGroup', groupData)
          }
        }

        return groupId
      } catch (e) {
        commit('setError', e)
        return null
      }
    },
    async fetchLastGroupIdByDate({ dispatch, commit }, courseId) {
      if (!courseId) {
        commit('setError', 'Ошибка')
        return false
      }

      let groupId = null

      try {
        const ref = collection(getFirestore(), 'groups')
        const q = query(ref, where('courseId', '==', courseId), orderBy('start', 'desc'), limit(1))
        const groups = await getDocs(q)
        if (!groups.empty) {
          for (const group of groups.docs) {
            let groupData = group.data()
            groupData.groupId = group.id
            groupId = group.id
            groupData = await dispatch('setDatesToDates', groupData)
            await commit('setGroup', groupData)
          }
        }

        return groupId
      } catch (e) {
        commit('setError', e)
        return null
      }
    },
    async fetchFutureGroups({ dispatch, commit }) {
      try {
        const ref = collection(getFirestore(), 'groups')
        const q = query(ref, where('end', '>', new Date()), orderBy('end', 'desc'), orderBy('courseId', 'asc'))
        await getDocs(q)
          .then(async groups => {
            if (!groups.empty) {
              const coursesFetched = []
              await groups.forEach(async group => {
                if (group.exists()) {
                  let groupData = {}
                  groupData = group.data()
                  groupData.groupId = group.id
                  if (groupData.courseId && coursesFetched.indexOf(groupData.courseId) < 0) {
                    coursesFetched.push(groupData.courseId)
                  }
                  groupData = await dispatch('setDatesToDates', groupData)
                  await commit('setGroup', groupData)
                  for (const courseId of coursesFetched) {
                    await commit('setFetchedAllGroupsForCourses', courseId)
                  }
                }
              })
            }
            await commit('setAllActiveFutureGroupsFetched')
            commit('resetGroups')
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchGroupsForPeriod({ getters, dispatch, commit }, { start, end }) {
      try {
        if (getters.loadedAllGroups) { return }
        if (!start || !end) { return }

        const ref = collection(getFirestore(), 'groups')
        const q = query(ref, where('start', '>=', start), where('end', '<=', end), orderBy('start', 'asc'))
        const groups = await getDocs(q)

        const data = {}

        if (!groups.empty) {
          for (const group of groups.docs) {
            data[group.id] = await dispatch('setDatesToDates', group.data())

            if (data[group.id].courseId && !getters.courses[data[group.id].courseId]) {
              await dispatch('fetchCourse', data[group.id].courseId)
            }
          }
        }

        return data
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchArchievedGroups({ dispatch, commit, getters }) {
      if (getters.loadedAllGroups) { return }
      const lim = 10

      try {
        const groupsRef = collection(getFirestore(), 'groups')
        const queries = [where('end', '<=', new Date()), orderBy('end', 'desc'), limit(lim)]

        const lastFetchedGroup = await getters.lastFetchedGroup
        if (lastFetchedGroup) {
          queries.push(startAfter(lastFetchedGroup))
        }

        const q = query(groupsRef, ...queries)
        await getDocs(q)
          .then(async groups => {
            if (!groups.empty) {
              await groups.forEach(async group => {
                if (group.exists()) {
                  let groupData = {}
                  groupData = group.data()
                  groupData.groupId = group.id
                  groupData = await dispatch('setDatesToDates', groupData)
                  await commit('setGroup', groupData)

                  if (groupData.courseId && !getters.courses[groupData.courseId]) {
                    await dispatch('fetchCourse', groupData.courseId)
                  }
                }
              })

              await commit('setLastFetchedGroup', groups.docs[groups.docs.length - 1])
            } else {
              await commit('setLastFetchedGroup', null)
            }

            if (groups.size < lim) {
              await commit('setLoadedAllGroups', true)
            }

            commit('resetGroups')
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchCourseGroups({ dispatch, commit }, courseId) {
      if (!courseId) {
        return
      }

      try {
        const ref = collection(getFirestore(), 'groups')
        const q = query(ref, where('courseId', '==', courseId), where('end', '>', new Date()), where('active', '==', true))
        await getDocs(q)
          .then(async groups => {
            if (!groups.empty) {
              await groups.forEach(async group => {
                if (group.exists()) {
                  let groupData = {}
                  groupData = group.data()
                  groupData.groupId = group.id
                  groupData = await dispatch('setDatesToDates', groupData)
                  await commit('setGroup', groupData)
                }
              })
            }
            await commit('setFetchedAllGroupsForCourses', courseId)
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async createNewGroup({ dispatch, commit, getters }, group) {
      if (!group) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        let groupId
        if (!group.courseId) {
          groupId = doc(collection(getFirestore(), 'groups')).id
        } else {
          if (!getters.courses[group.courseId]) {
            await dispatch('fetchCourse', group.courseId)
          }
          groupId = getters.courses[group.courseId].landingPage + '-' + (new Date(group.start).getFullYear()) + '-' + (new Date(group.start).getMonth() + 1) + '-' + (new Date(group.start).getDate()) + '-' + (new Date(group.start).getHours()) + '-' + (new Date(group.start).getMinutes()) + '-' + +new Date()
        }

        const batch = writeBatch(getFirestore())
        const groupRef = doc(getFirestore(), `groups/${groupId}`)
        batch.set(groupRef, group)

        await batch.commit()

        group.groupId = groupId
        await commit('setGroup', group)
        commit('resetGroups')

        return groupId
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async editGroup({ commit }, { groupId, data }) {
      try {
        if (!data || !groupId) {
          commit('setError', 'Ошибка')
          return false
        }

        if (data.dates && data.dates.length) {
          for (const dt in data.dates) {
            data.dates[dt].start = new Date(data.dates[dt].start)
          }
        }

        const batch = writeBatch(getFirestore())

        const groupRef = doc(getFirestore(), `groups/${groupId}`)
        batch.update(groupRef, data)

        data.groupId = groupId

        await batch.commit().then(async () => {
          await commit('setGroup', data)
          commit('resetGroups')
          return groupId
        })
      } catch (e) {
        commit('setError', e)
        return false
      }
      return groupId
    },
    setDatesToDates(context, group) {
      if (group.start && group.start.toDate) { group.start = group.start.toDate() }
      if (group.end && group.end.toDate) { group.end = group.end.toDate() }
      if (group.dates) {
        for (const dt in group.dates) {
          if (group.dates[dt].start && group.dates[dt].start.toDate) {
            group.dates[dt].start = group.dates[dt].start.toDate()
          }
        }
      }
      return group
    }
  },
  getters: {
    groups: s => s.groups,
    filteredGroupsArr: (s, getters) => {
      if (getters.groupsReset) {
        //
      }

      let answer = []

      if (Object.keys(s.groups) && Object.keys(s.groups).length) {
        answer = Object.keys(s.groups)
      }

      if (answer.length) {
        answer = answer.filter(groupId => {
          const group = s.groups[groupId]

          const formatsStr = Object.values(getters.formats).join(';')

          return (
            (!s.groupsFilters.searchStr || (
              (group.name && group.name.toLowerCase().includes(s.groupsFilters.searchStr.toLowerCase()))
              || (group.address && group.address.toLowerCase().includes(s.groupsFilters.searchStr.toLowerCase()))
              || (group.format && group.format.toLowerCase().includes(s.groupsFilters.searchStr.toLowerCase()))
              || (formatsStr.toLowerCase().includes(s.groupsFilters.searchStr.toLowerCase()))
              || (group.nethouseEventId && group.nethouseEventId === s.groupsFilters.searchStr)
              || (
                group.courseId && getters.courses[group.courseId] &&
                (
                  (getters.courses[group.courseId].publicName && getters.courses[group.courseId].publicName.toLowerCase().includes(s.groupsFilters.searchStr.toLowerCase())) ||
                  (getters.courses[group.courseId].shortName && getters.courses[group.courseId].shortName.toLowerCase().includes(s.groupsFilters.searchStr.toLowerCase())) ||
                  (getters.courses[group.courseId].landingPage && getters.courses[group.courseId].landingPage.toLowerCase().includes(s.groupsFilters.searchStr.toLowerCase()))
                )
              )
            ))
            && (
              !s.groupsFilters.hasStudents
              || (group.numberOfStudents && group.numberOfStudents > 0)
            )
            && (
              s.groupsFilters.showFinishedGroups
              || (+group.end >= Date.now())
            )
            && (
              s.groupsFilters.showInActiveGroups
              || group.active
            )
            && (
              !s.groupsFilters.type
              || group.type === s.groupsFilters.type
            )
            && (
              !s.groupsFilters.format
              || group.format === s.groupsFilters.format
            )
            && (
              !s.groupsFilters.courseId
              || group.courseId === s.groupsFilters.courseId
            )
          )
        })
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          const startA = +s.groups[a].start
          const startB = +s.groups[b].start
          if (startA < startB) { return -1 }
          if (startA > startB) { return 1 }
          return 0
        })
      }

      return answer
    },
    groupsFiltered: (s, getters) => {
      if (getters.groupsFilters.searchStr
        || getters.groupsFilters.hasStudents
        || getters.groupsFilters.showFinishedGroups
        || getters.groupsFilters.showInActiveGroups
        || getters.groupsFilters.type
        || getters.groupsFilters.format
        || getters.groupsFilters.courseId) {
        return true
      }
      return false
    },
    futureGroups: (s, getters) => {
      if (getters.groupsReset) {
        //
      }

      let answer = []

      if (Object.keys(s.groups) && Object.keys(s.groups).length) {
        answer = Object.keys(s.groups)
      }

      answer = answer.filter(groupId => {
        const group = s.groups[groupId]
        return (group.start && +group.start > +new Date())
      })

      answer.sort((a, b) => {
        const startA = +new Date(getters.groups[a].start)
        const startB = +new Date(getters.groups[b].start)
        if (startA < startB) { return -1 }
        if (startA > startB) { return 1 }
        return 0
      })

      return answer
    },
    futureGroupsWithStudentsArr: (s, getters) => {
      if (getters.groupsReset) {
        //
      }

      let answer = []

      if (Object.keys(s.groups) && Object.keys(s.groups).length) {
        answer = Object.keys(s.groups)
      }

      answer = answer.filter(groupId => {
        const group = s.groups[groupId]
        return ((group.end && +group.end > +new Date()) && (group.numberOfStudents && +group.numberOfStudents > 0))
      })

      answer.sort((a, b) => {
        const startA = +new Date(getters.groups[a].start)
        const startB = +new Date(getters.groups[b].start)
        if (startA < startB) { return -1 }
        if (startA > startB) { return 1 }
        return 0
      })

      return answer
    },
    allActiveFutureGroupsFetched: s => s.allActiveFutureGroupsFetched,
    fetchedAllGroupsForCourses: s => s.fetchedAllGroupsForCourses,
    lastFetchedGroup: s => s.lastFetchedGroup,
    loadedAllGroups: s => s.loadedAllGroups,
    groupsFilters: s => s.groupsFilters,
    groupsReset: s => s.groupsReset,
    showGroupsFilters: s => s.showGroupsFilters,
    groupsScheduleMonth: s => s.groupsScheduleMonth,
    groupsScheduleYear: s => s.groupsScheduleYear
  }
}
